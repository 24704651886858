import { EUserType } from 'services/BackendApi';

export const SET_ACTING_ON_BEHALF_OF_UUID = 'actingOnBehalfOf/SET_ACTING_ON_BEHALF_OF_UUID';
export const SET_ACTING_ON_BEHALF_OF_ROLE = 'actingOnBehalfOf/SET_ACTING_ON_BEHALF_OF_ROLE';
export const SET_ACTING_ON_BEHALF_OF_USER = 'actingOnBehalfOf/SET_ACTING_ON_BEHALF_OF_USER';

export type SetActingOnBehalfOfUuidAction = ReturnType<typeof setActingOnBehalfOfUuidAction>;
export const setActingOnBehalfOfUuidAction = (uuid: string | null) => ({
  type: SET_ACTING_ON_BEHALF_OF_UUID as typeof SET_ACTING_ON_BEHALF_OF_UUID,
  uuid,
});

export type SetActingOnBehalfOfRoleAction = ReturnType<typeof setActingOnBehalfOfRoleAction>;
export const setActingOnBehalfOfRoleAction = (role: EUserType | null) => ({
  type: SET_ACTING_ON_BEHALF_OF_ROLE as typeof SET_ACTING_ON_BEHALF_OF_ROLE,
  role,
});

export type SetActingOnBehalfOfUserAction = ReturnType<typeof setActingOnBehalfOfUserAction>;
export const setActingOnBehalfOfUserAction = (user: { uuid: string; role: EUserType | null } | null) => ({
  type: SET_ACTING_ON_BEHALF_OF_USER as typeof SET_ACTING_ON_BEHALF_OF_USER,
  user,
});

export type TActingOnBehalfOfActions =
  | SetActingOnBehalfOfUuidAction
  | SetActingOnBehalfOfRoleAction
  | SetActingOnBehalfOfUserAction;

import { createSelector } from 'reselect';
import { IActingOnBehalfOfDomain } from './model';

export const domainSelector = (state: any): IActingOnBehalfOfDomain => state.actingOnBehalfOf;

export const actingOnBehalfOfUuidSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain) => domain.actingOnBehalfOfUuid
);

export const actingOnBehalfOfRoleSelector = createSelector(
  domainSelector,
  (domain: IActingOnBehalfOfDomain) => domain.actingOnBehalfOfRole
);

export const actingOnBehalfOfUserSelector = createSelector(domainSelector, (domain: IActingOnBehalfOfDomain) => {
  return {
    uuid: domain.actingOnBehalfOfUuid,
    role: domain.actingOnBehalfOfRole,
  };
});

import { BookingBuilder } from 'services/BackendApi';
import {
  IBasketBuild,
  IBasketBuildWithHotelPhoto,
  IBasketBuildWithHotelPhotoTaMarginAmount,
} from 'services/BackendApi/types/Basket';

export const getHotelImage = (build: IBasketBuildWithHotelPhoto) => {
  return build.hotelPhotoUrl;
};

export const convertBasketBuildToBookingBuilderFormat = (
  basketBuild: IBasketBuild | IBasketBuildWithHotelPhoto | IBasketBuildWithHotelPhotoTaMarginAmount
): BookingBuilder => {
  return {
    bookingErrors: basketBuild.initialBuildResponse.errors,
    request: basketBuild.buildRequest,
    response: basketBuild.initialBuildResponse,
  };
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { BasketRightColumn } from './BasketRightColumn';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { Multiselect } from 'ui/Multiselect';
import { Link } from 'react-router-dom';
import { ENetworkRequestStatus, EUserType, IUser, makeBackendApi } from 'services/BackendApi';
import { BasketItem } from './BasketItem';
import { IBasketBuildWithHotelPhoto, IBasketUserResponseItem } from 'services/BackendApi/types/Basket';
import classNames from 'classnames';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { History } from 'history';
import { ERoutingPreviousPage } from 'utils/routingUtils';
import * as InventoryHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { isNil } from 'lodash-es';
import * as ActingOnBehalfOfSelectors from 'store/modules/actingOnBehalfOf/selectors';
import * as ActingOnBehalfOfActions from 'store/modules/actingOnBehalfOf/actions';
import { useRawUrlParams } from 'hooks/useRawUrlParams';

interface IBasketPageParams {
  buildUuid?: string;
  travelAgentUuid?: string;
}

export const BasketPage: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory<History.LocationState>();
  const isInventoryHeaderOpen = useSelector(InventoryHeaderSelectors.isMenuOpenSelector);
  const isTa = useSelector(AuthSelectors.isTA);
  const loggedInUser = useSelector(AuthSelectors.getCurrentUser) as IUser;
  const actingOnBehalfOfUuid = useSelector(ActingOnBehalfOfSelectors.actingOnBehalfOfUuidSelector);

  const params = useRawUrlParams<IBasketPageParams>();
  const { buildUuid: queryParamBuildUuid, travelAgentUuid: queryParamTaUuid } = params;

  const [shouldShowLeaveAlert, setShouldShowLeaveAlert] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const [builds, setBuilds] = useState<IBasketBuildWithHotelPhoto[]>([]);
  const [totalBuilds, setTotalBuilds] = useState<number | null>(null);
  const [getBasketRequestPageLoad, setGetBasketRequestPageLoad] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getBasketRequestOffsetChange, setGetBasketRequestOffsetChange] = useState<ENetworkRequestStatus>(
    ENetworkRequestStatus.IDLE
  );
  const [getBasketUsersRequest, setGetBasketUsersRequest] = useState<ENetworkRequestStatus>(ENetworkRequestStatus.IDLE);
  const [selectedBuildUuid, setSelectedBuildUuid] = useState<string | null>(queryParamBuildUuid ?? null);
  const [basketUsers, setBasketUsers] = useState<IBasketUserResponseItem[]>([]);
  const [selectedTaUserUuid, setSelectedTaUserUuid] = useState<string | null>(queryParamTaUuid ?? null);

  const backendApi = makeBackendApi();
  const selectedBasketBuild = builds.find(build => build.uuid === selectedBuildUuid);

  const selectedBasketUser = basketUsers.find(user => user.basketOwner.uuid === actingOnBehalfOfUuid);

  useEffect(() => {
    if (redirectLocation) {
      history.push(redirectLocation, { comingFrom: ERoutingPreviousPage.BOOKING_CONFIRMATION_PAGE });
    }
  }, [redirectLocation, history]);

  // getting the basket users
  useEffect(() => {
    if (isTa) {
      return;
    }

    dispatch(
      ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
        uuid: loggedInUser.uuid,
        role: loggedInUser.type as EUserType,
      })
    );

    setGetBasketUsersRequest(ENetworkRequestStatus.PENDING);
    backendApi
      .getBasketUsers()
      .then(res => {
        setGetBasketUsersRequest(ENetworkRequestStatus.SUCCESS);
        setBasketUsers(res.data);

        // if the logged in user is one of the basket owners, set their first TA as the selected TA
        // TODO this is going to change in future based on access points
        const me = res.data.find(user => user.basketOwner.uuid === loggedInUser.uuid);
        if (me && me.travelAgents.length >= 1 && isNil(selectedTaUserUuid)) {
          setSelectedTaUserUuid(me!.travelAgents[0].uuid);
        }
      })
      .catch(e => {
        setGetBasketUsersRequest(ENetworkRequestStatus.ERROR);
      });
  }, [isTa]);

  // getting the basket items
  useEffect(() => {
    if (!isTa && (isNil(actingOnBehalfOfUuid) || isNil(selectedTaUserUuid))) {
      return;
    }

    offset === 0
      ? setGetBasketRequestPageLoad(ENetworkRequestStatus.PENDING)
      : setGetBasketRequestOffsetChange(ENetworkRequestStatus.PENDING);

    backendApi
      .getBasket({
        offset,
        actingOnBehalfOfUserUuid: isTa ? undefined : actingOnBehalfOfUuid,
        travelAgentUuid: isTa ? undefined : selectedTaUserUuid,
      })
      .then(async res => {
        // get photos for all the hotels...
        const hotelUuids = res.data.builds.map(build => build.initialBuildResponse.hotel.uuid);
        const uploadsForHotels = await backendApi.getPhotosForHotels(hotelUuids);

        // ...and then put a photo into the build for the hotel
        const buildsWithHotelPhotos = res.data.builds.map(build => {
          const hotelUploads = uploadsForHotels.data.data.filter(
            upload => upload.ownerUuid === build.initialBuildResponse.hotel.uuid
          );
          let hotelPhoto = hotelUploads.find(upload => upload.tag === 'featuredPhoto');
          if (!hotelPhoto) {
            hotelPhoto = hotelUploads[0];
          }
          return {
            ...build,
            hotelPhotoUrl: hotelPhoto?.url,
          };
        });

        setBuilds([...builds, ...buildsWithHotelPhotos]);
        setTotalBuilds(res.data.total);
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.SUCCESS)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.SUCCESS);
      })
      .catch(error => {
        offset === 0
          ? setGetBasketRequestPageLoad(ENetworkRequestStatus.ERROR)
          : setGetBasketRequestOffsetChange(ENetworkRequestStatus.ERROR);
      });
  }, [isTa, actingOnBehalfOfUuid, selectedTaUserUuid, offset]);

  return (
    <div className="basket-page container mx-auto max-w-[1800px] font-pt-sans text-black px-[20px]">
      <div className="basket-columns flex gap-[40px] mt-[20px]">
        {/* the widths are the size of the right coluimn AND the x padding of the container */}
        <div
          className={classNames('basket-left-column flex flex-col w-[calc(100%-280px)] lg:w-[calc(100%-390px)]', {
            'opacity-50 pointer-events-none': getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING,
          })}
        >
          <div className="page-title-container flex items-center justify-between mb-20px">
            <h1 className={'primary-title font-noe-display text-4xl leading-46px text-black font-normal m-0'}>
              Basket <span className="text-26px leading-33">- Select Product</span>
            </h1>
          </div>

          {/* the "user" dropdowns */}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.PENDING && <LoadingBar />}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.ERROR && <ErrorBar />}
          {!isTa && getBasketUsersRequest === ENetworkRequestStatus.SUCCESS && (
            <div className="filters grid grid-cols-2 gap-5 mb-20px">
              <div className="flex flex-col space-y-2 w-full">
                <span>Basket Created by *</span>
                <Multiselect
                  className="bg-white !font-hurmegeometric-sans"
                  itemsClassname="bg-white"
                  itemCtaClassName="hover:bg-gray-10"
                  itemContentClassName="!font-hurmegeometric-sans"
                  options={basketUsers.map(bu => {
                    return {
                      value: bu.basketOwner.uuid,
                      label: `${bu.basketOwner.firstName} ${bu.basketOwner.lastName}::${bu.basketOwner.type}`,
                    };
                  })}
                  isCloseOnSelect={true}
                  hideCheckboxes={true}
                  isSingleSelectMode={true}
                  optionsLabelRenderer={o => o.label.split('::')[0]}
                  selectedValuesRenderer={v => {
                    const [label, role] = v.split('::');
                    return label;
                  }}
                  onUpdate={(sv, isCustom, options) => {
                    if (sv.length <= 0) {
                      dispatch(ActingOnBehalfOfActions.setActingOnBehalfOfUserAction(null));
                    } else {
                      const user = options.find(o => o.value === sv[0]);
                      const [label, role] = user.label.split('::');
                      dispatch(
                        ActingOnBehalfOfActions.setActingOnBehalfOfUserAction({
                          uuid: sv[0],
                          role,
                        })
                      );
                    }
                    setSelectedTaUserUuid(null);
                    setOffset(0);
                    setBuilds([]);
                    setSelectedBuildUuid(null);
                    setGetBasketRequestPageLoad(ENetworkRequestStatus.IDLE);
                  }}
                  selectedValues={selectedBasketUser ? [selectedBasketUser.basketOwner.uuid] : []}
                  hideDropdownArrow
                />
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <span>Travel Company - Travel Agent *</span>
                <Multiselect
                  className="bg-white !font-hurmegeometric-sans"
                  itemsClassname="bg-white !font-hurmegeometric-sans"
                  itemCtaClassName="hover:bg-gray-10"
                  itemContentClassName="!font-hurmegeometric-sans"
                  options={
                    selectedBasketUser
                      ? selectedBasketUser.travelAgents.map(ta => {
                          return {
                            value: ta.uuid,
                            label: `${ta.company.name} - ${ta.firstName} ${ta.lastName}`,
                          };
                        })
                      : []
                  }
                  isCloseOnSelect={true}
                  hideCheckboxes={true}
                  isSingleSelectMode={true}
                  onUpdate={sv => {
                    if (sv.length <= 0) {
                      setSelectedTaUserUuid(null);
                    } else {
                      setSelectedTaUserUuid(sv[0]);
                    }
                    setOffset(0);
                    setBuilds([]);
                    setSelectedBuildUuid(null);
                    setGetBasketRequestPageLoad(ENetworkRequestStatus.IDLE);
                  }}
                  selectedValues={selectedTaUserUuid ? [selectedTaUserUuid] : []}
                  hideDropdownArrow
                />
              </div>
            </div>
          )}

          {/* results but no results */}
          {getBasketRequestPageLoad === ENetworkRequestStatus.PENDING &&
            getBasketUsersRequest !== ENetworkRequestStatus.PENDING && <LoadingBar />}
          {getBasketRequestPageLoad === ENetworkRequestStatus.ERROR && <ErrorBar />}
          {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && builds.length <= 0 && (
            <div className="flex flex-col">
              <span className="text-15px leading-18px mb-20px">No results found</span>

              <Link
                className="block self-start bg-white border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm"
                to="/filters"
              >
                <span className="text-brown-prime">Search Products</span>
              </Link>
            </div>
          )}

          {/* results */}
          <div className="flex flex-col space-y-10px mt-20px">
            {getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS &&
              builds.map(build => (
                <BasketItem
                  key={build.uuid}
                  build={build}
                  isSelected={selectedBuildUuid === build.uuid}
                  onSelectBuild={uuid => {
                    setSelectedBuildUuid(uuid);
                  }}
                />
              ))}
          </div>

          {/* the load more button */}
          {totalBuilds && builds.length < totalBuilds && getBasketRequestPageLoad === ENetworkRequestStatus.SUCCESS && (
            <button
              className="bg-white mt-20px self-center block border border-brown-prime font-hurmegeometric-sans uppercase px-10px py-[9px] text-brown-prime text-sm items-center space-x-5px cursor-pointer min-w-[125px]"
              disabled={getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING}
              onClick={() => {
                setOffset(offset + 10);
              }}
            >
              {getBasketRequestOffsetChange === ENetworkRequestStatus.PENDING ? (
                <i className="fas fa-circle-notch fa-spin text-brown-140"></i>
              ) : (
                'Load More'
              )}
            </button>
          )}
        </div>

        <div
          className={classNames(
            'basket-right-column-wrapper fixed right-[20px] w-[260px] lg:w-[370px] transition-[top] duration-300',
            {
              // the odd heights are to ensure that we can always scroll down the right hand side and see everything
              // we need them because the right column is position:fixed
              'overflow-y-auto max-h-[calc(100vh-200px)] top-[190px]': isInventoryHeaderOpen,
              'overflow-y-auto max-h-[calc(100vh-150px)] top-[140px]': !isInventoryHeaderOpen,
            }
          )}
        >
          <BasketRightColumn
            basketBuild={selectedBasketBuild || null}
            shouldShowLeaveAlert={shouldShowLeaveAlert}
            setShouldShowLeaveAlert={setShouldShowLeaveAlert}
            setRedirectLocation={setRedirectLocation}
          />
        </div>
      </div>

      <LeaveWithoutSavingModal
        title="You have made changes to this page, and not saved. If you leave this page now, these changes will be lost."
        when={shouldShowLeaveAlert}
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </div>
  );
});

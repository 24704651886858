import {
  all,
  allPass,
  anyPass,
  both,
  complement,
  defaultTo,
  equals,
  fromPairs,
  has,
  isNil,
  keys,
  last,
  lensPath,
  lensProp,
  mapObjIndexed,
  mergeDeepLeft,
  mergeDeepRight,
  omit,
  over,
  partial,
  path,
  pickBy,
  pipe,
  prop,
  propOr,
  propSatisfies,
  reject,
  set,
  toPairs,
} from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { push } from 'connected-react-router';
import { makeBackendApi } from 'services/BackendApi';
import { ProductTypes, BookingStatusTypes } from 'config/enums';
import { successAction, errorFromResponse, genericAction } from 'store/common';
import { getBooking, getBookingForBuilder, getBookingForBuilderPure } from './selectors';
import { offersQuerySelector } from 'store/modules/fastSearch/selectors';
import { addFinalDayToBooking } from './utils';
import { selectedTaSelector, selectedCompanyMembershipSelector } from '../agents';
import { enqueueNotification } from 'store/modules/ui/actions';

export const BOOKING_AMEND = 'BOOKING_AMEND';
export const BOOKING_CANCEL = 'BOOKING_CANCEL';
export const BOOKING_CHECKS = 'BOOKING_CHECKS';
export const BOOKING_CREATED_REMOVE = 'BOOKING_CREATED_REMOVE';
export const BOOKING_FETCH = 'BOOKING_FETCH';
export const BOOKING_POPULATE = 'BOOKING_POPULATE';
export const BOOKING_POPULATE_BULK = 'BOOKING_POPULATE_BULK';
export const BOOKING_RELEASE = 'BOOKING_RELEASE';
export const BOOKING_REMOVE = 'BOOKING_REMOVE';
export const BOOKING_REQUEST = 'BOOKING_REQUEST';
export const BOOKING_RESET = 'BOOKING_RESET';
export const BOOKING_REVIEW = 'BOOKING_REVIEW';
export const BOOKING_ROOM_ADD = 'BOOKING_ROOM_ADD';
export const BOOKING_ROOM_REMOVE = 'BOOKING_ROOM_REMOVE';
export const BOOKING_ROOM_UPDATE = 'BOOKING_ROOM_UPDATE';
export const BOOKING_SUBMIT = 'BOOKING_SUBMIT';
export const BOOKING_UPDATE = 'BOOKING_UPDATE';
export const BOOKINGS_SET = 'BOOKINGS_SET';
export const BOOKINGS_FETCH = 'BOOKINGS_FETCH';
export const BACKWARDS_COMPAT = 'BACKWARDS_COMPAT';

const omitProps = [
  'agesOfAllChildren',
  'agreeToTerms',
  'bookingComments',
  'bookingHash',
  'breakdown',
  'canBeBooked',
  'checkInDate',
  'checkOutDate',
  'clientUuid',
  'createdAt',
  'deletedAt',
  'hotelName',
  'hotelUuid',
  'internalComments',
  'marginApplied',
  'mustStop',
  'numAdults',
  'overrideTotal',
  'payment',
  'status',
  'touched',
  'updatedAt',
  'uuid',
];

/**
 * Prop is not empty or nil
 *
 * Function that waits for key and data
 *
 * @returns {Function}
 */
const propIsNotEmptyOrNil = complement(propSatisfies(isNilOrEmpty));

/**
 * Has start and end date
 *
 * Checks an object has both start and end date
 *
 * @param {object}
 * @returns {boolean}
 */
const hasStartAndEndDate = both(propIsNotEmptyOrNil('startDate'), propIsNotEmptyOrNil('endDate'));

/**
 * All have start and end date
 *
 * Checks that an array of objects all have start and end dates
 *
 * @param {Array<Object>}
 */
const allHaveStartAndEndDate = all(hasStartAndEndDate);

/**
 * Has accommodation
 *
 * Checks to see if a booking has accommodation products
 *
 * @param {object}
 * @returns {boolean}
 */
const hasAccommodation = allPass([
  // Has acommodation product key
  propIsNotEmptyOrNil(ProductTypes.ACCOMMODATION),
  pipe(propOr([], ProductTypes.ACCOMMODATION), allHaveStartAndEndDate),
]);

/**
 * Ignore call
 *
 * List of predicates to determine if booking builder call should be ignored
 *
 * @param {object}
 * @returns {boolean}
 */
const ignoreCall = anyPass([has('marginApplied'), has('taMarginType'), has('taMarginAmount')]);

/**
 * Get hotel name
 *
 * @param {object}
 * @returns {string}
 */
const getHotelName = path(['breakdown', 'hotel', 'name']);

/**
 * Update booking action
 *
 * @param {object}
 * @param {object}
 * @returns {object}
 */
const updateBookingAction = partial(genericAction, [BOOKING_UPDATE]);

/**
 * Update booking action
 *
 * This is the main action for the booking flow.  Responsible for formatting the
 * data in a booking object and passing it on to the booking builder
 *
 * @param {string} id Booking ID
 * @param {object} payload
 * @param {boolean} forceCall
 * @returns {Function}
 */
export const updateBooking = (id, payload, forceCall = false) => async (dispatch, getState) => {
  dispatch(updateBookingAction(payload));

  // Success fast if nothing was passed to update booking
  if (!payload) return dispatch(successAction(BOOKING_UPDATE, {}));

  const state = getState();

  const prevBooking = getBooking(state, id);

  // If there is no hotel info then we assume the booking ID is the hotel ID
  const hotelUuid = propOr(id, 'hotelUuid', prevBooking);

  let nextBooking = {
    ...payload,
    hotelUuid,
    hotelName: getHotelName(prevBooking),
    touched: false,
  };

  const nextState = over(lensPath(['bookings', 'data', id]), pipe(defaultTo({}), mergeDeepLeft(nextBooking)), state);

  const bookingBuilderPayload = getBookingForBuilderPure(nextState, id);

  const withAccommodation = hasAccommodation(bookingBuilderPayload);

  // Whether there should be a booking builder call
  const shouldCall = forceCall || (!ignoreCall(payload) && withAccommodation);

  const selectedTa = selectedTaSelector(state);
  let breakdown = {};

  const searchQuery = offersQuerySelector(state);

  try {
    const response =
      shouldCall &&
      (await makeBackendApi(selectedTa?.uuid).postBookingBuilderRequest(
        bookingBuilderPayload,
        searchQuery.clientCountryCode
      ));

    // If there was no accommodation then we need to set the potentialBooking accommodation key to empty
    if (!withAccommodation) {
      breakdown = set(lensPath(['potentialBooking', ProductTypes.ACCOMMODATION]), [], breakdown);
    }

    if (response) {
      const {
        data: { data },
      } = response;

      // Replace the breakdown with the new data
      breakdown = data;

      // if we have aggregate totals, we need to completely overwrite that in the breakdown
      // to ensure it is fully updated, otherwise we might no longer have a total but it is
      // kept in state

      // Hotel name needs to be pulled from the hotel object
      nextBooking.hotelName = path(['hotel', 'name'], breakdown);
    }
  } catch (e) {
    dispatch(errorFromResponse(BOOKING_UPDATE, e, 'Could not update booking. Please try again later.'));
    return;
  }

  dispatch(successAction(BOOKING_UPDATE, { [id]: mergeDeepRight(nextBooking, { breakdown }) }));
};

/**
 * helper function
 *
 * @param {object} state
 * @param {string} id Booking ID
 * @returns {object}
 */
const getBookingBasicPayload = (state, id, dispatch) => {
  let booking = getBooking(state, id);
  if (!booking) {
    booking = state.bookings.data['undefined'];
  }

  const { breakdown } = booking;

  const bookingBuild = getBookingForBuilder(state, id);
  const bookingHash = prop('bookingHash', breakdown);

  // attempt to get a bookingHash if none exists
  // TODO 1043 why would this scenario ever happen
  if (!bookingHash) {
    dispatch(
      enqueueNotification({
        message: 'There was an error creating the booking. Go back and try again.',
        options: { variant: 'error' },
      })
    );
    return;
  }

  // Get the booking information from the booking object.  This is the final screen
  // in the booking flow
  const bookingInformation = pipe(
    omit(omitProps),
    toPairs,
    // Filter out empty keys
    reject(pipe(last, isNil)),
    fromPairs
  )(booking);

  // Final payload is the object that the backend will accept
  return {
    bookingBuild,
    bookingHash,
    bookingInformation,
  };
};

/**
 * Create booking with proposal action
 *
 * @param {string} id Booking ID
 * @param {object} payload
 * @param {object} newProposalInfo
 * @param {object} backendApi
 * @returns {Function}
 */
export const createBookingWithNewProposal = (
  id,
  payload,
  newProposalInfo,
  backendApi,
  bookingBuild = undefined,
  taMarginAmountOverride = ''
) => async (dispatch, getState) => {
  await dispatch(updateBooking(id, payload));

  // Final payload is the object that the backend will accept

  let finalPayload = {};
  if (bookingBuild) {
    console.log('payload', payload);
    console.log('newProposalInfo', newProposalInfo);
    console.log('bookingBuild inside proposal', bookingBuild);
    if (bookingBuild) {
      finalPayload.status = BookingStatusTypes.POTENTIAL;
      finalPayload.placeHolds = false;
      finalPayload.bookingHash = bookingBuild.response.bookingHash;
      finalPayload.bookingBuild = bookingBuild.request;
      finalPayload.bookingInformation = {
        guestTitle: payload.guestTitle,
        guestFirstName: payload.guestFirstName,
        guestLastName: payload.guestLastName,
        isRepeatGuest: false,
        taMarginType: 'percentage',
        comments: payload.comments,
        specialRequests: payload.specialRequests,
      };
    }
    if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
      finalPayload.bookingInformation.taMarginAmount = taMarginAmountOverride;
    }
  } else {
    const generatedPayload = getBookingBasicPayload(getState(), id, dispatch);
    finalPayload = {
      ...generatedPayload,
      status: BookingStatusTypes.POTENTIAL,
      placeHolds: false,
    };
  }
  const searchQuery = offersQuerySelector(getState());

  const selectedCompanyMembership = selectedCompanyMembershipSelector(getState());

  try {
    const booking = await backendApi.createBooking(
      finalPayload,
      newProposalInfo,
      searchQuery.clientCountryCode,
      selectedCompanyMembership
    );
    await dispatch(updateBooking(id, { ...booking }));

    dispatch(push('/search/beta'));
  } catch (e) {
    dispatch(errorFromResponse(BOOKING_SUBMIT, e, 'There was a problem creating your booking.'));
  }
};

/**
 * Complete booking action
 *
 * @param {string} id Booking ID
 * @param {object} payload
 * @param {string} status
 * @returns {Function}
 */
export const completeBooking = (id, payload, status = BookingStatusTypes.REQUESTED) => async (dispatch, getState) => {
  await dispatch(updateBooking(id, payload));

  // Final payload is the object that the backend will accept
  const generatedPayload = getBookingBasicPayload(getState(), id, dispatch);
  const finalPayload = {
    ...generatedPayload,
    status,
  };

  const selectedTa = selectedTaSelector(getState());
  const backendApi = makeBackendApi(selectedTa?.uuid);

  dispatch(genericAction(BOOKING_SUBMIT, finalPayload));

  const searchQuery = offersQuerySelector(getState());
  const selectedCompanyMembership = selectedCompanyMembershipSelector(getState());
  try {
    const booking = await backendApi.createBooking(
      finalPayload,
      null,
      searchQuery.clientCountryCode,
      selectedCompanyMembership
    );
    dispatch(successAction(BOOKING_SUBMIT, { id, data: booking }));
  } catch (e) {
    dispatch(errorFromResponse(BOOKING_SUBMIT, e, 'There was a problem creating your booking.'));
  }
};

/**
 * Set bookings action
 *
 * Sets bookings from proposals
 *
 * @param {object} data
 * @returns {Function}
 */
export const setBookings = data => dispatch => {
  dispatch(genericAction(BOOKINGS_SET, bookings));

  // Adds the missing day in the proposal bookings data that is returned
  const bookings = mapObjIndexed(addFinalDayToBooking, data);

  dispatch(successAction(BOOKINGS_SET, bookings));
};

export const backwardCompatBookingBuilderAction = (
  hotelUuid,
  request,
  response,
  marginType,
  marginAmount,
  travelAgentUserUuid
) => {
  return {
    type: BACKWARDS_COMPAT,
    payload: {
      hotelUuid,
      request,
      response,
      marginType,
      marginAmount,
      travelAgentUserUuid,
    },
  };
};

import React, { useState } from 'react';
import { IBasketBuild, IBasketBuildWithHotelPhoto } from 'services/BackendApi/types/Basket';
import { convertBasketBuildToBookingBuilderFormat, getHotelImage } from './utils';
import { SvgIcon } from 'ui/SvgIcon';
import Star from 'ui/Icons/star.component.svg';
import { PriorityHighComponent } from 'ui/Icons';
import CopyIcon from 'ui/Icons/copy.component.svg';
import PencilIcon from 'ui/Icons/pencil-no-circle.component.svg';
import TrashIcon from 'ui/Icons/trash-no-circle.component.svg';
import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { formatDateDisplay, formatPrice, generateArrayOfDatesBetween, getCurrencySymbol } from 'utils';
import { ERoomRateType } from 'store/modules/bookingBuilder';
import { Radio } from 'ui/Radio';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { BasketItemExpandableInfo } from './BasketItemExpandableInfo';
import { useTranslation } from 'react-i18next';
import ArrowDown from 'ui/Icons/components/ArrowDown.component';
import { theme } from '../../../tailwind.config';

interface IBasketItemProps {
  build: IBasketBuildWithHotelPhoto;
  isSelected: boolean;
  onSelectBuild: (buildUuid: string) => void;
}

const countString = (itemCount: number, noun: string) => {
  if (itemCount === 0) {
    return `No ${noun}s`;
  } else if (itemCount === 1) {
    return `${itemCount} ${noun}`;
  } else {
    return `${itemCount} ${noun}s`;
  }
};

const RateTypeBadge = ({ rateType }: { rateType: ERoomRateType }) => {
  switch (rateType) {
    case ERoomRateType.STATIC:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px uppercase">
          static rate
        </span>
      );

    case ERoomRateType.LIVE:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-teal-20 text-12px leading-14px uppercase">
          live rate
        </span>
      );
    default:
      return (
        <span className="block self-end font-hurmegeometric-sans py-2px px-5px text-black bg-white-true text-12px leading-14px">
          static rate
        </span>
      );
  }
};

export const BasketItem = (props: IBasketItemProps) => {
  const startDate = props.build.buildRequest.startDate;
  const endDate = props.build.buildRequest.endDate;
  const dateRangeString = `${formatDateDisplay(startDate)} - ${formatDateDisplay(endDate)}`;
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const lengthOfStay = generateArrayOfDatesBetween(startDate, endDate).length;

  const accommodationCount = props.build.buildRequest.Accommodation.length;
  const transferCount = props.build.buildRequest.Transfer.length;
  const groundServiceCount = props.build.buildRequest['Ground Service'].length;

  const guestCount =
    props.build.buildRequest.guestAges.numberOfAdults + props.build.buildRequest.guestAges.agesOfAllChildren.length;

  const currencySymbol = getCurrencySymbol(props.build.initialBuildResponse.currency);

  let rateType = ERoomRateType.STATIC;
  if (props.build.initialBuildResponse.potentialBooking.Accommodation[0].isLiveRate) {
    rateType = ERoomRateType.LIVE;
  }

  const isOnRequest = props.build.initialBuildResponse.totals.oneOrMoreItemsOnRequest;

  const isDiscounted =
    props.build.initialBuildResponse.totals.totalBeforeDiscountForPricedItemsCents >
    props.build.initialBuildResponse.totals.totalForPricedItemsCents;

  const bookingBuild = convertBasketBuildToBookingBuilderFormat(props.build);
  const lodgingSummaries = BookingBuilderUtils.generateLodgingSummariesFromBooking(t, bookingBuild);

  return (
    <div
      id={`basket-item-${props.build.uuid}`}
      className={classNames('flex flex-col bg-gray-3 py-15px px-20px', {
        'outline outline-teal-100': props.isSelected,
      })}
    >
      <div className={classNames('flex items-center justify-around space-x-20px', {})}>
        <span className="1st-col w-[25px]">
          <span onClick={() => props.onSelectBuild(props.build.uuid)} className="block ml-5px">
            <Radio
              className="!border-brown-100"
              color={theme.colors['brown-100']}
              checked={props.isSelected}
              onClick={() => props.onSelectBuild(props.build.uuid)}
            />
          </span>
        </span>
        <span className="2nd-col w-[200px] hidden xl:block">
          <img
            onClick={() => props.onSelectBuild(props.build.uuid)}
            className="w-full aspect-video cursor-pointer"
            src={props.build.hotelPhotoUrl}
            alt="Hotel photo"
          />
        </span>
        <span className="3rd-col flex-grow">
          <span className="flex justify-between mb-10px">
            <div>
              <span className="flex items-center uppercase space-x-2 mb-5px">
                <SvgIcon IconComponent={Star} className="w-4 h-4 fill-brown-prime" />
                <span className="text-brown-prime text-12px leading-14px mt-3px">5 star</span>
              </span>
              {/* hotel name */}
              <span className="block font-noe-display text-brown-prime text-[20px] mb-5px">
                {props.build.initialBuildResponse.hotel.name}
              </span>
              {/* hotel region */}
              <span className="block font-hurmegeometric-sans text-brown-prime text-[14px] uppercase mb-15px">
                {props.build.initialBuildResponse.hotel.region}
              </span>

              <span className="flex items-center space-x-2 font-hurmegeometric-sans uppercase mb-10px">
                <span className="font-bold text-[13px] p-2px ">{dateRangeString}</span>
                <span className="bg-teal-20 text-13px p-2px">{lengthOfStay} nights</span>
                <span className="text-13px">{countString(guestCount, 'guest')}</span>
                <span className="text-13px">{countString(accommodationCount, 'room')}</span>
              </span>
              <span className="flex items-center space-x-2 font-hurmegeometric-sans uppercase">
                <span className="text-13px">{countString(transferCount, 'transfer')}</span>
                <span className="text-13px">{countString(groundServiceCount, 'ground service')}</span>
              </span>
            </div>
            <div className="flex flex-col space-y-5px">
              <span className="flex self-end items-center space-x-2">
                {/* <SvgIcon IconComponent={PriorityHighComponent} className="w-5 h-5 fill-brown-prime" /> */}
                <SvgIcon IconComponent={CopyIcon} className="w-5 h-5 fill-brown-prime" />
                <SvgIcon IconComponent={PencilIcon} className="w-5 h-5" />
                <SvgIcon IconComponent={TrashIcon} className="w-5 h-5" />
              </span>
              <RateTypeBadge rateType={rateType} />

              {isOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="font-hurmegeometric-sans text-black text-15px leading-20px font-[600] text-right">
                    On Request
                  </span>
                </div>
              ) : null}

              {!isDiscounted && !isOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-black text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(props.build.initialBuildResponse.totals.total)}
                  </span>
                </div>
              ) : null}

              {isDiscounted && !isOnRequest ? (
                <div className="flex flex-col space-y-1">
                  <span className="uppercase font-hurmegeometric-sans text-flint text-13px font-bold text-right">
                    total cost
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-red-92 text-19px leading-20px font-[600] text-right">
                    {currencySymbol}
                    {formatPrice(props.build.initialBuildResponse.totals.total)}
                  </span>
                  <span className="uppercase font-hurmegeometric-sans text-gray-80 text-15px leading-18px text-right line-through">
                    {currencySymbol}
                    {formatPrice(props.build.initialBuildResponse.totals.totalBeforeDiscount)}
                  </span>
                </div>
              ) : null}
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={classNames(
                  'self-end cursor-pointer flex justify-around items-center h-6 w-6 hover:bg-gray-40 active:bg-gray-40 bg-transparent border-none outline-none'
                )}
              >
                <SvgIcon
                  IconComponent={ArrowDown}
                  className={classNames('transition-transform w-[14px] stroke-black', {
                    'rotate-180': isExpanded,
                  })}
                />
              </button>
            </div>
          </span>
          {/* Hiding until future ticket */}
          {/* <span className="text-brown-prime text-13px leading-16px uppercase underline font-hurmegeometric-sans">
            add commission
          </span> */}
        </span>
      </div>
      <AnimateHeight duration={300} height={isExpanded ? 'auto' : 0} className={classNames('accordion-area', {})}>
        <BasketItemExpandableInfo key={props.build.uuid} booking={bookingBuild} lodgingSummaries={lodgingSummaries} />
      </AnimateHeight>
    </div>
  );
};

import { EUserType } from 'services/BackendApi';

export interface IActingOnBehalfOfDomain {
  actingOnBehalfOfUuid: string | null;
  actingOnBehalfOfRole: EUserType | null;
}

export const initialState: IActingOnBehalfOfDomain = {
  actingOnBehalfOfUuid: null,
  actingOnBehalfOfRole: null,
};

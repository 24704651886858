import * as Actions from './actions';
import { IActingOnBehalfOfDomain, initialState } from './model';
import { produce } from 'immer';

export const actingOnBehalfOfReducer = (
  state: IActingOnBehalfOfDomain = initialState,
  action: Actions.TActingOnBehalfOfActions
): IActingOnBehalfOfDomain => {
  switch (action.type) {
    case Actions.SET_ACTING_ON_BEHALF_OF_UUID:
      return produce(state, draftState => {
        draftState.actingOnBehalfOfUuid = action.uuid;
        return draftState;
      });

    case Actions.SET_ACTING_ON_BEHALF_OF_ROLE:
      return produce(state, draftState => {
        draftState.actingOnBehalfOfRole = action.role;
        return draftState;
      });

    case Actions.SET_ACTING_ON_BEHALF_OF_USER:
      return produce(state, draftState => {
        if (action.user === null) {
          draftState.actingOnBehalfOfUuid = null;
          draftState.actingOnBehalfOfRole = null;
          return draftState;
        }
        draftState.actingOnBehalfOfUuid = action.user.uuid;
        draftState.actingOnBehalfOfRole = action.user.role;
        return draftState;
      });

    default:
      return state;
  }
};

export default actingOnBehalfOfReducer;
